import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import LanguageSwitchIcon from '../icons/LanguageSwitchIcon'

interface IProps {
    langCode: 'en' | 'id'
    changeLang: (lang: 'en' | 'id') => void
    className?: string
    isOpen?: boolean
    setOpen?: Dispatch<SetStateAction<boolean>>
    closeAccountDropdown?: () => void
    isScrolled?: boolean
    newHeaderType?: string
}

const LanguageSwitch = (props: IProps) => {
    const [languageOpen, setLanguageOpen] = useState(false)

    useEffect(() => {
        window.addEventListener('click', (e) => {
            e.stopPropagation()
            props.setOpen ? props.setOpen(false) : setLanguageOpen(false)
        })

        return () => {
            window.removeEventListener('click', (e) => {
                e.stopPropagation()
                props.setOpen ? props.setOpen(false) : setLanguageOpen(false)
            })
        }
    })

    const handleLanguageOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        if ((props.isOpen !== undefined ? !props.isOpen : !languageOpen) && props.closeAccountDropdown) props.closeAccountDropdown()
        props.setOpen ? props.setOpen(prev => !prev) : setLanguageOpen(prev => !prev)
    }

    const handleLocationSelected = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, lang: 'en' | 'id') => {
        e.stopPropagation()
        props.setOpen ? props.setOpen(false) : setLanguageOpen(false)
        if (lang !== props.langCode) {
            props.changeLang(lang)
        }
    }

    return (
        <div className={`${props.className || ''} flex flex-row relative cursor-pointer`} onClick={handleLanguageOpen}>
            <LanguageSwitchIcon
                className={`mr-2 w-auto h-auto fill-current ${props.newHeaderType === 'homepage' ? `${props.isScrolled ? "text-black-48" : 'text-white'}` : "text-black-48"}`}
            />
            {/* <img src={`${process.env.NEXT_PUBLIC_ASSET_URL}/assets/images/svg/language.svg`} className="mr-1 w-auto h-auto" alt='language switch' /> */}
            <p className={`lg:text-sm mr-auto lg:mr-2 ${props.newHeaderType === 'homepage' ? `${props.isScrolled ? "text-black-48" : 'text-white'}` : "text-black-48"}`}>{props.langCode === 'en' ? 'English' : 'Bahasa'}</p>
            <FontAwesomeIcon icon="caret-down" size="xs" className={`mr-2 lg:mr-0 ${props.newHeaderType === 'homepage' ? `${props.isScrolled ? "text-black-48" : 'text-white'}` : "text-black-48"} transform transition-all duration-200 ease-linear ${(props.isOpen !== undefined ? props.isOpen : languageOpen) ? 'rotate-180' : ''}`} />
            {
                (props.isOpen !== undefined ? props.isOpen : languageOpen) &&
                // <div className={`absolute top-full lg:mt-4 left-0 right-0 w-full py-1 ml-2 lg:ml-0 rounded-3px border-0 lg:border border-gray-0033 lg:text-sm text-black-48 ${props.newHeaderType === 'homepage' ? `${props.isScrolled ? "bg-white" : 'bg-white-FFFFFF66 backdrop-blur'}` : "bg-white"}`}>
                    <div className={`absolute top-full lg:mt-4 left-0 right-0 w-full py-1 ml-2 lg:ml-0 rounded-3px border-0 lg:border border-gray-0033 lg:text-sm text-black-48 bg-white`}>
                        <p onClick={e => handleLocationSelected(e, 'id')} className="px-4 py-1 cursor-pointer hover:bg-blue-e6">Bahasa</p>
                        <p onClick={e => handleLocationSelected(e, 'en')} className="px-4 py-1 cursor-pointer hover:bg-blue-e6">English</p>
                    </div>
            }
                </div>
    )
}

            export default LanguageSwitch
