import { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { isLg, parseLanguage, sendGA } from '../../utils/Helper';
import { useRouter } from 'next/dist/client/router';
import classes from './Header.module.css'
import LoginModal from '../modals/LoginModal';
import { generateUrl } from '../../utils/Helper';
import dynamic from 'next/dynamic';
import { _removeToken } from '../../utils/Cookie';
import LanguageSwitch from './LanguageSwitch';
import Hamburger from '../forms/Hamburger';
import JendelaLogo from '../icons/Logo';
import ProfileIcon from '../icons/ProfileIcon';

const AutoSuggest = dynamic(() => import('../forms/AutoSuggest'))

export interface HeaderProps {
    lang: any
    isTransparent?: boolean
    isBlue?: boolean
    langCode?: string
    withAds?: boolean
    setIsLoginPage?: (isLoggedIn: boolean) => void
    /** used to pass flag for opening the login modal when necessary */
    isLoginModalOpen?: number
    /** used to pass flag for opening sidebar */
    isSidebarOpen?: boolean
    setIsSidebarOpen?: (isOpen: boolean) => void
    sidebarZIndex?: string
    url?: string
    id_url?: string
    en_url?: string
    /**
     * @example
     * listing-sewa: transparent mobile and white desktop
     * location-page: transparent desktop and mobile
     */
    newHeaderType?: 'listing-sewa' | 'location-page' | 'homepage'
    headerzIndex?: string
}

interface Props {
    /** used for passing logged in state to pages */
    handleSidebarDella: (status: boolean) => void
}

export default function Header({ lang, isTransparent, isBlue, langCode, withAds, setIsLoginPage, isLoginModalOpen, isSidebarOpen, handleSidebarDella, setIsSidebarOpen, sidebarZIndex, url, newHeaderType, headerzIndex }: HeaderProps & Props) {
    const [openSideBar, setOpenSideBar] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isLoginOpen, setLoginOpen] = useState(false)
    const [isDropDownOpen, setIsDropDownOpen] = useState(false)
    const [isLanguageDropDownOpen, setIsLanguageDropDownOpen] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const [isLoginLoading, setIsLoginLoading] = useState(true)
    const [type, setType] = useState('login')
    const router = useRouter()
    const [isOwner, setIsOwner] = useState<any>()
    const [refCode, setRefCode] = useState('')
    const [isBM, setIsBM] = useState(false)

    const onStorageEvent = (e: any) => {
        if (e.key === "token" && e.newValue !== "undefined") {
            setLoginOpen(false)
            setIsLoggedIn(true)
            if (setIsLoginPage) setIsLoginPage(true)
            try {
                const name = localStorage.getItem('jendela_username')
                if (name) {
                    sessionStorage.setItem('toast', JSON.stringify({
                        message: parseLanguage(lang.welcome_with_name, ':name', name),
                        options: {
                            type: 'success',
                        },
                    }))
                    const pathname = location.pathname
                    if (pathname.includes('/signin') || pathname.includes('/signup')) {
                        const prevUrl = sessionStorage.getItem('prevUrl')
                        if (prevUrl) {
                            sessionStorage.removeItem('prevUrl')
                            location.href = prevUrl
                        } else {
                            location.href = generateUrl(langCode === 'en' ? 'en' : 'id', '')
                        }
                    } else {
                        location.reload()
                    }
                }
            } catch {
                // console.error('Cookies disabled')
            }
        }
    }

    useEffect(() => {
        try {
            const localStorageRefCode = localStorage.getItem('ref')
            if (localStorageRefCode) {
                setRefCode(localStorageRefCode)
            }
        } catch {
            // console.error('Cookies disabled')
        }
        setIsMobile(!isLg())
        window.addEventListener('scroll', handleScroll)
        window.addEventListener('resize', handleResize)
        window.addEventListener('click', closeDropDown)
        window.addEventListener('storage', onStorageEvent, false);
        checkIsOwner()
        checkIsBM()
        try {
            setIsLoggedIn(!!window.localStorage.getItem('token'))
            if (setIsLoginPage) setIsLoginPage(!!window.localStorage.getItem('token'))
        } catch {
            // console.error('Cookies disabled')
        }
        setIsReady(true)
        return () => {
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('click', closeDropDown)
            window.removeEventListener('storage', onStorageEvent, false);
        }
    }, [])

    useEffect(() => {
        if (isReady) {
            setIsLoginLoading(false)
        }
    }, [isLoggedIn, isReady])

    useEffect(() => {
        if (isLoginModalOpen) {
            setLoginOpen(true)
        }
    }, [isLoginModalOpen])

    useEffect(() => {
        if (isSidebarOpen !== undefined) setOpenSideBar(isSidebarOpen)
    }, [isSidebarOpen])

    const handleScroll = () => {
        if (document.documentElement.scrollTop > 38 && !isScrolled) {
            setIsScrolled(true)
        } else {
            setIsScrolled(false)
        }
    }

    const handleResize = () => {
        setIsMobile(!isLg())
    }

    const changeLang = (lang_code: string) => {
        const current_url = window.location.href
        let new_url = ''
        if (lang_code === 'id') {
            let url_array = current_url.split('/')
            if (url_array.includes('en')) {
                new_url = current_url.replace('/en', '')
            }
        } else if (lang_code === 'en') {
            let url_array = current_url.split('/')
            if (!url_array.includes('en')) {
                url_array.splice(3, 0, 'en')
                new_url = url_array.join('/')
                if (new_url[new_url.length - 1] === '/') {
                    new_url = new_url.slice(0, new_url.length - 1)
                }
            }
        }
        if (new_url) {
            window.location.replace(new_url)
        }
    }

    const setLogin = (login: boolean) => {
        if (login) {
            setIsLoggedIn(true)
            if (setIsLoginPage) setIsLoginPage(true)
        }
    }

    const openLogin = () => {
        setType('login')
        setLoginOpen(true)
    }

    const openRegister = () => {
        setType('register')
        setLoginOpen(true)
    }

    const toggleDropDown = (e: any) => {
        e.stopPropagation()
        setIsDropDownOpen(!isDropDownOpen)
        setIsLanguageDropDownOpen(false)
    }

    const closeDropDown = () => {
        setIsDropDownOpen(false)
    }

    const logout = () => {
        import('../../utils/DefaultAxios')
            .then(DefaultAxios => DefaultAxios.default)
            .then(DefaultAxios => {
                DefaultAxios.post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/logout`)
                    .finally(() => {
                        try {
                            localStorage.removeItem('token')
                            localStorage.removeItem('account_type')
                            localStorage.removeItem('expired_in')
                            localStorage.removeItem('jendela_username')
                            localStorage.removeItem('is_owner')
                            localStorage.removeItem('ref')
                            _removeToken()
                        } catch {
                            // console.error('Cookies disabled')
                        }
                        setIsLoggedIn(false)
                        if (setIsLoginPage) setIsLoginPage(false)

                        location.reload()
                    })
            })
    }

    const checkIsOwner = () => {
        try {
            const owner = window.localStorage.getItem('is_owner')
            setIsOwner(owner)
        } catch {
            // console.error('Cookies disabled')
            return
        }
    }

    const checkIsBM = () => {
        try {
            const type = window.localStorage.getItem('account_type')
            if (type && type === 'bm') {
                setIsBM(true)
            }
        } catch (error) {
            return
        }
    }

    const renderName = () => {
        try {
            const name = window.localStorage.getItem('jendela_username')
            if (!name) return ''
            return `${name.slice(0, 10)}${name.length > 10 ? '...' : ''}`
        } catch {
            // console.error('Cookies disabled')
            return ''
        }
    }

    const sendGASearch = () => {
        const current_url = window.location.href
        if (current_url === `${process.env.NEXT_PUBLIC_MAINSITE_URL}/` || current_url === `${process.env.NEXT_PUBLIC_MAINSITE_URL}/en`) {
            sendGA('search', 'search-prop')
        }
    }

    const sendGAListing = () => {
        const current_url = window.location.href
        if (current_url === `${process.env.NEXT_PUBLIC_MAINSITE_URL}/` || current_url === `${process.env.NEXT_PUBLIC_MAINSITE_URL}/en`) {
            sendGA('listing', 'sewakan-mid')
        }
    }

    useEffect(() => {
        if (openSideBar) {
            handleSidebarDella(true)
        } else {
            handleSidebarDella(false)
        }
    }, [openSideBar])

    return (
        <>
            <div className={`fixed top-0 lg:px-4 h-nav-mobile lg:h-nav-desktop flex flex-col justify-center w-full ${headerzIndex || 'z-20'} transition-colors duration-300 ease-in-out ${newHeaderType === 'listing-sewa' ?
                (isScrolled ? 'bg-white border-b border-gray-nav' : 'bg-transparent lg:bg-white') :
                newHeaderType === 'location-page' ?
                    (isScrolled ? 'bg-white border-b border-gray-nav' : 'bg-white')
                    : newHeaderType === 'homepage' ?
                        (isScrolled ? 'bg-white border-b border-gray-nav' : 'border-b border-transparent bg-transparent')
                        : 'bg-white border-b border-gray-nav'
                }`}>
                <div className={`flex relative items-center container`}>
                    <a href={generateUrl(langCode === 'en' ? 'en' : 'id', '/')} className={`cursor-pointer`}>
                        {
                            newHeaderType === 'homepage' ?
                                <JendelaLogo
                                    useCurrentColor={!openSideBar && !isScrolled}
                                    className={`w-auto h-38px lg:h-49px ${isScrolled ? 'text-transparent' : 'text-white'} `}
                                /> :
                                <img src={`https://assets.jendela360.com/jendela/assets/images/svg/logo.svg`} alt="logo" className={`w-auto h-38px lg:h-49px fill-current bg-clip-text ${isScrolled ? 'bg-transparent' : 'bg-white'} `} />
                        }
                    </a>
                    {
                        (!isTransparent && !isBlue && !isMobile && newHeaderType !== 'listing-sewa') ?
                            <div className="relative hidden lg:block ml-4">
                                <AutoSuggest lang={lang} langCode={langCode || 'id'} />
                            </div>
                            : null
                    }
                    <div className={`flex-row items-center ${newHeaderType === 'homepage' ? `${isScrolled ? "text-black-48" : 'text-white'}` : "text-black-48"} ${!isTransparent && !isBlue ? 'ml-auto' : 'ml-10 mr-auto'} hidden lg:flex`}>
                        <a href={`${generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/apartemen`)}`} className={`mr-8 text-sm  transition-colors duration-100 ease-linear hover:text-main active:text-main`}>{lang.map}</a>
                        <a href={`${generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/search`)}`} onClick={() => sendGASearch()} className={`mr-8 text-sm  transition-colors duration-100 ease-linear hover:text-main active:text-main`}>{lang.search_unit}</a>
                    </div>
                    <div className={`flex flex-row items-center ml-auto md:ml-0 absolute right-0 lg:relative lg:right-auto`}>
                        <a href={`${generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/listing`)}`} onClick={() => sendGAListing()} className={`mr-8 text-sm ${newHeaderType === 'homepage' ? `${isScrolled ? "text-black-48" : 'text-white'}` : "text-black-48"} hidden lg:flex flex-row items-center transition-colors duration-100 ease-linear hover:text-main active:text-main`}>
                            {lang.list}
                            <span className="text-2xs uppercase text-white py-1 px-1 bg-red-7b rounded-full font-bold ml-1 leading-none">
                                {lang.free_capitalize}
                            </span>
                        </a>
                        <div className="flex-row items-center hidden lg:flex">
                            {
                                (url?.includes('signin') || url?.includes('signup') || url?.includes('recover') || url?.includes('reset')) ?
                                    null
                                    :
                                    isLoginLoading ?
                                        <div className="LazyLoad rounded-md h-5 w-24 opacity-75 mr-8" />
                                        :
                                        isLoggedIn ?
                                            <div className="relative mr-8">
                                                <button onClick={(e) => toggleDropDown(e)} className={`${classes['dropdown-toggle']} ${isDropDownOpen ? classes['dropdown-toggle-open'] : ''} ${newHeaderType === 'homepage' ? `${isScrolled ? "text-black-48" : 'text-white'}` : "text-black-48"} flex flex-row items-center text-sm outline-none focus:outline-none w-full`}>
                                                    {/* <img src={`${process.env.NEXT_PUBLIC_ASSET_URL}/assets/images/svg/profile.svg`} width="20" height="20" alt="profile" className="mr-1" /> */}
                                                    <ProfileIcon 
                                                        name='profile'
                                                        className={`w-6 h-6 mr-1 fill-current ${newHeaderType === 'homepage' ? `${isScrolled ? "text-black-48" : 'text-white'}` : "text-black-48"}`}
                                                    />
                                                    <p className="ml-px mr-2">{renderName()}</p>
                                                </button>
                                                <div className={`absolute text-sm bottom-0 right-0 -mb-4 py-2 text-black-48  bg-white border border-gray-0033 shadow transform translate-y-full transition duration-200 ease-linear rounded-3px ${isDropDownOpen ? '' : classes['dropdown-close']}`} style={{ width: 180 }}>
                                                    <a href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/favourites`} className=" text-sm flex flex-row py-2 px-4 hover:bg-blue-e6 items-center focus:outline-none">
                                                        <img src={`https://assets.jendela360.com/jendela/assets/images/svg/heart-alt.svg`} alt="heart" className="mr-3 w-4 h-4 object-contain" width="16" height="16" />
                                                        <p>{lang.favourite}</p>
                                                    </a>

                                                    {
                                                        refCode !== '' &&
                                                        <a href={generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/account/ref-code`)} className=" text-sm flex flex-row py-2 px-4 hover:bg-blue-e6 items-center focus:outline-none">
                                                            <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/datadisplay-icon-referallink.svg`} alt="referal" className="mr-3 w-4 h-4 object-contain" width="16" height="16" />
                                                            <p>Referal Link</p>
                                                        </a>
                                                    }
                                                    <a href={generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/account`)} className=" text-sm flex flex-row py-2 px-4 hover:bg-blue-e6 items-center focus:outline-none">
                                                        <img src={`${process.env.NEXT_PUBLIC_ASSET_URL}/assets/images/svg/profile.svg`} alt="account" className="mr-3 w-4 h-4 object-contain" width="16" height="16" />
                                                        <p>Account</p>
                                                    </a>
                                                    {
                                                        isOwner === '1' ?
                                                            <a href={generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/owner-dashboard`)} className=" text-sm flex flex-row py-2 px-4 hover:bg-blue-e6 items-center focus:outline-none">
                                                                <img src={`https://assets.jendela360.com/jendela/assets/images/svg/unit-key.svg`} alt="unit-key" className="mr-3 w-4 h-4 object-contain" width="16" height="16" />
                                                                <p>Owner Dashboard</p>
                                                            </a>
                                                            : null
                                                    }

                                                    {
                                                        isBM ?
                                                            <a href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/bm-dashboard`} className="text-sm flex flex-row py-2 px-4 hover:bg-blue-e6 items-center focus:outline-none">
                                                                <img src={`https://assets.jendela360.com/jendela/assets/images/svg/bm-dashboard.svg`} alt="heart" className="mr-3 w-4 h-4 object-contain" width="16" height="16" />
                                                                <p>BM Dashboard</p>
                                                            </a>
                                                            : null
                                                    }


                                                    <hr className="border-gray-0000001A mx-4 my-1" />
                                                    <button onClick={() => logout()} className=" text-sm flex flex-row py-2 px-4 w-full outline-none focus:outlline-none hover:bg-blue-e6 items-center focus:outline-none">
                                                        <img src={`https://assets.jendela360.com/jendela/assets/images/svg/signout-alt.svg`} alt="signout" className="mr-3 w-4 h-4 object-contain" width="16" height="16" />
                                                        <p>{lang.log_out}</p>
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            <div className="flex flex-row items-center mr-8">
                                                <button onClick={() => openLogin()} className={`text-sm ${newHeaderType === 'homepage' ? `${isScrolled ? "text-black-48" : 'text-white'}` : "text-black-48"} transition-colors duration-100 ease-linear hover:text-main outline-none focus:outline-none`}>
                                                    {lang.login}
                                                </button>
                                                <span className={`${newHeaderType === 'homepage' ? `${isScrolled ? "text-black-48" : 'text-white'}` : "text-black-48"} mx-1`}>/</span>
                                                <button onClick={() => openRegister()} className={`text-sm ${newHeaderType === 'homepage' ? `${isScrolled ? "text-black-48" : 'text-white'}` : "text-black-48"} transition-colors duration-100 ease-linear hover:text-main outline-none focus:outline-none`}>
                                                    {lang.sign_up}
                                                </button>
                                                {/* <Button
                                            variant="plain"
                                            size="md"
                                            classes="mr-5"
                                            plainClasses={`hover:text-main active:text-main`}
                                            fontColor={'text-black-48'}
                                            fontSize="text-sm"
                                            onClick={() => openLogin()}
                                        >
                                            {lang.login}
                                        </Button> */}
                                                {/* <Button href={generateUrl(typeof langCode !== "undefined" ? langCode : "id", "/signup")} variant={isTransparent ? 'outlined' : 'plain'} size="md" fontColor={!isTransparent ? 'text-black-48' : undefined} borderColor={!isTransparent ? 'border-black-48' : undefined} onClick={() => openRegister()}> */}
                                                {/* <Button
                                            href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/signup`}
                                            variant='plain'
                                            size="md"
                                            plainClasses={`hover:text-main active:text-main`}
                                            fontColor={'text-black-48'}
                                            fontSize="text-sm"
                                            onClick={() => openRegister()}
                                        >
                                            {lang.sign_up}
                                        </Button> */}
                                            </div>
                            }
                            {/* typeof langCode !== "undefined" && langCode === 'en' ? 'English' : 'Bahasa' */}
                            <LanguageSwitch
                                langCode={typeof langCode !== "undefined" && langCode === 'en' ? 'en' : 'id'}
                                changeLang={changeLang}
                                className="items-center"
                                isOpen={isLanguageDropDownOpen}
                                setOpen={setIsLanguageDropDownOpen}
                                closeAccountDropdown={() => closeDropDown()}
                                isScrolled={isScrolled}
                                newHeaderType={newHeaderType}
                            />
                        </div>
                        {
                            (isTransparent || isBlue || newHeaderType === 'listing-sewa') ?
                                <div className='md:hidden'>
                                    <Hamburger
                                        onClick={() => setOpenSideBar(!openSideBar)}
                                        open={openSideBar}
                                        className={newHeaderType === 'homepage' ? `${(isScrolled || openSideBar) ? "text-black-48" : 'text-white'}` : 'text-black-48'}
                                    />
                                </div>
                                // <button
                                //     className={`lg:hidden hamburger hamburger--slider ${openSideBar ? 'is-active' : ''}`}
                                //     onClick={() => setOpenSideBar(!openSideBar)}
                                //     style={{ padding: '0' }}
                                //     type="button"
                                // >
                                //     <span className="hamburger-box">
                                //         <span className="hamburger-inner"></span>
                                //     </span>
                                // </button>
                                :
                                <button className="absolute lg:hidden outline-none active:outline-none focus:outline-none" style={{ width: 25, height: 25, right: 7 }} onClick={() => setOpenSideBar(true)}>
                                    <img src={`https://assets.jendela360.com/jendela/assets/images/svg/search-menu.svg`} alt="search button" />
                                </button>
                        }
                    </div>
                </div>
            </div>
            {
                isReady ?
                    <Sidebar
                        langCode={langCode ? langCode : "id"}
                        lang={lang}
                        show={openSideBar}
                        white={!isTransparent && !isBlue}
                        onClickClose={() => setIsSidebarOpen ? setIsSidebarOpen(false) : setOpenSideBar(false)}
                        isLoggedIn={isLoggedIn}
                        logout={logout}
                        changeLang={changeLang}
                        sidebarZIndex={sidebarZIndex}
                        url={url}
                        isOwner={isOwner}
                        isBM={isBM}
                    />
                    : null
            }
            {
                isReady &&
                <LoginModal
                    langCode={langCode}
                    lang={lang}
                    isOpen={isLoginOpen}
                    close={() => setLoginOpen(false)}
                    type={type}
                    changeType={setType}
                    setLogin={setLogin}
                    from="header"
                />
            }
        </>
    )
}