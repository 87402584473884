import React, { SVGProps } from 'react'

type Props = {}

const ProfileIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_3640_1166)">
                <path d="M16.5104 5.48959C14.905 3.88416 12.7704 3 10.5 3C8.22961 3 6.09501 3.88416 4.48959 5.48959C2.88416 7.09501 2 9.22961 2 11.5C2 13.7704 2.88416 15.905 4.48959 17.5104C6.09501 19.1158 8.22961 20 10.5 20C12.7704 20 14.905 19.1158 16.5104 17.5104C18.1158 15.905 19 13.7704 19 11.5C19 9.22961 18.1158 7.09501 16.5104 5.48959ZM5.68905 17.254C5.96972 14.839 8.04608 12.9744 10.5 12.9744C11.7936 12.9744 13.0101 13.4784 13.9251 14.3933C14.6981 15.1665 15.1857 16.1759 15.3111 17.2539C14.0076 18.3456 12.3293 19.0039 10.5 19.0039C8.67072 19.0039 6.99253 18.3457 5.68905 17.254ZM10.5 11.9485C9.07655 11.9485 7.91833 10.7903 7.91833 9.36683C7.91833 7.94324 9.07655 6.78516 10.5 6.78516C11.9235 6.78516 13.0817 7.94324 13.0817 9.36683C13.0817 10.7903 11.9235 11.9485 10.5 11.9485ZM16.1684 16.4119C15.9146 15.3885 15.3853 14.445 14.6294 13.6891C14.0172 13.0769 13.293 12.6176 12.5031 12.3298C13.4526 11.6859 14.0778 10.5979 14.0778 9.36683C14.0778 7.3941 12.4727 5.78906 10.5 5.78906C8.52727 5.78906 6.92223 7.3941 6.92223 9.36683C6.92223 10.5986 7.5479 11.6869 8.49821 12.3307C7.77151 12.5956 7.09901 13.005 6.52042 13.5441C5.68256 14.3243 5.10073 15.3193 4.83096 16.4111C3.68843 15.094 2.99609 13.3764 2.99609 11.5C2.99609 7.36232 6.36232 3.99609 10.5 3.99609C14.6377 3.99609 18.0039 7.36232 18.0039 11.5C18.0039 13.3768 17.3113 15.0948 16.1684 16.4119Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_3640_1166">
                    <rect width="17" height="17" fill="currentColor" transform="translate(2 3)" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default ProfileIcon