import React from 'react'

type Props = {
    onClick: () => void
    open: boolean
    className?: string
}

const Hamburger = (props: Props) => {
    return (
        <button className={`${props.className || "text-black-48"} w-10 h-10 relative focus:outline-none bg-transparent`} onClick={props.onClick} >
            <div className="block w-5 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2">
                <span aria-hidden="true" style={{ height: 2 }} className={`block absolute w-5 bg-current transform transition-all duration-500 ease-in-out ${props.open ? 'rotate-45' : '-translate-y-1.5'}  `}></span>
                <span aria-hidden="true" style={{ height: 2 }} className={`block absolute w-5 bg-current   transform transition-all duration-500 ease-in-out ${props.open ? 'opacity-0' : ''} `}></span>
                <span aria-hidden="true" style={{ height: 2 }} className={`block absolute w-5 bg-current transform  transition-all duration-500 ease-in-out ${props.open ? '-rotate-45' : ' translate-y-1.5'} `}></span>
            </div >
        </button >
    )
}

export default Hamburger