import React, { SVGProps } from 'react'

const LanguageSwitchIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
            <path d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0Zm7.784,12.153-2.56.442A13.431,13.431,0,0,0,14.7,9.3h2.7A8.344,8.344,0,0,1,16.784,12.153ZM.607,9.3H3.3a13.431,13.431,0,0,0,.471,3.295l-2.56-.442A8.344,8.344,0,0,1,.607,9.3Zm.609-3.453,2.56-.442A13.431,13.431,0,0,0,3.3,8.7H.607A8.344,8.344,0,0,1,1.216,5.847ZM9.3,4.817a20.638,20.638,0,0,1,3.216.294l1.059.183A12.877,12.877,0,0,1,14.1,8.7H9.3Zm3.318-.3a21.265,21.265,0,0,0-3.318-.3V.617c1.72.165,3.214,1.74,4.061,4.032ZM8.7,4.217a21.261,21.261,0,0,0-3.318.3l-.743.128C5.486,2.355,6.98.78,8.7.616Zm-3.216.894A20.633,20.633,0,0,1,8.7,4.817V8.7H3.9a12.877,12.877,0,0,1,.52-3.407ZM3.9,9.3H8.7v3.883a20.638,20.638,0,0,1-3.216-.294l-1.059-.183A12.877,12.877,0,0,1,3.9,9.3Zm1.477,4.181a21.337,21.337,0,0,0,3.318.3v3.6c-1.72-.165-3.214-1.74-4.061-4.032Zm3.918.3a21.335,21.335,0,0,0,3.318-.3l.743-.128c-.847,2.292-2.341,3.868-4.061,4.032Zm3.216-.9a20.633,20.633,0,0,1-3.216.294V9.3h4.8a12.877,12.877,0,0,1-.52,3.407ZM14.7,8.7a13.431,13.431,0,0,0-.471-3.295l2.56.442A8.344,8.344,0,0,1,17.393,8.7ZM16.48,5.186l-2.453-.423A8.273,8.273,0,0,0,11.64,1.027,8.44,8.44,0,0,1,16.48,5.186ZM6.36,1.027A8.273,8.273,0,0,0,3.973,4.762L1.52,5.186A8.44,8.44,0,0,1,6.36,1.027ZM1.52,12.814l2.453.423A8.273,8.273,0,0,0,6.36,16.973,8.44,8.44,0,0,1,1.52,12.814Zm10.12,4.158a8.273,8.273,0,0,0,2.387-3.735l2.453-.423A8.44,8.44,0,0,1,11.64,16.973Zm0,0" />
        </svg>
    )
}

export default LanguageSwitchIcon