import dynamic from "next/dynamic";
import { generateUrl } from "../../utils/Helper";
import LanguageSwitch from "./LanguageSwitch";
import { useState, useEffect } from 'react';
import { useMounted } from "../../hooks/MountedHooks";

const AutoSuggest = dynamic(() => import("../forms/AutoSuggest"))

interface IProps {
    langCode: string
    lang: any
    show: boolean
    white?: boolean
    onClickClose: Function
    isLoggedIn: boolean
    logout: () => void
    changeLang: (lang: string) => void
    sidebarZIndex?: string
    url?: string
    isOwner?: string
    isBM?: boolean
}

export default function Filter({ langCode, isBM, lang, white, show, onClickClose, isLoggedIn, logout, changeLang, sidebarZIndex, url, isOwner }: IProps) {
    const isMounted = useMounted()

    const [refCode, setRefCode] = useState('')

    const name = () => {
        try {
            return window.localStorage.getItem('jendela_username')
        } catch {
            // console.error('Cookies disabled')
            return ''
        }
    }

    useEffect(() => {
        try {
            const localStorageRefCode = localStorage.getItem('ref')
            if (localStorageRefCode) {
                setRefCode(localStorageRefCode)
            }
        } catch {
            // console.error('Cookies disabled')
        }
    })

    return (
        isMounted ?
            <div className={`bg-white text-black-48 ${white ? `${sidebarZIndex || 'z-30'} px-4` : `${sidebarZIndex || 'z-10'} pt-16 p-5`} fixed top-0 left-0 h-full w-full text-base overflow-y-auto pb-20 transform ${show ? '' : 'translate-x-full'} ease-in-out duration-100 block lg:hidden`}>
                <div className="flex flex-col pt-2">
                    {
                        white && show ?
                            <div className="relative mb-4 flex flex-row justify-between">
                                <AutoSuggest lang={lang} langCode={langCode} isMobile />
                                {/* <Button variant="plain" fontColor="black-48" onClick={() => props.onClickClose()}>
                            <FontAwesomeIcon icon="times" width="0" size="lg" />
                        </Button> */}
                                <div className="mx-2"></div>
                                <button
                                    className={`lg:hidden hamburger hamburger--slider is-active gray`}
                                    onClick={() => onClickClose()}
                                    style={{ padding: 0 }}
                                    type="button"
                                >
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner"></span>
                                    </span>
                                </button>
                            </div>
                            : null
                    }
                    {/* <div className="flex items-center">
                    {
                        white ?
                            <img src={`https://assets.jendela360.com/jendela/assets/images/svg/house-alt.svg`} style={{ height: 16 }} />
                            :
                            <img src={`https://assets.jendela360.com/jendela/assets/images/svg/house.svg`} style={{ height: 16 }} />
                    }
                    <div className="ml-2">
                        {lang.home}
                    </div>
                </div> */}

                    {/* <hr className="my-2"></hr> */}

                    <div className="flex mb-2">
                        <a href={generateUrl(langCode, "/apartemen")}>
                            {lang.search_apart}
                        </a>
                    </div>

                    <div className="flex my-2">
                        <a href={generateUrl(langCode, "/search")}>
                            {lang.search_unit}
                        </a>
                    </div>

                    <div className="flex my-2">
                        <a href={generateUrl(langCode, "/listing")} className="flex flex-row items-center">
                            {lang.list_yours_property}
                            <span className="text-2xs uppercase text-white py-1 px-2 bg-red-7b rounded-full font-bold ml-1 leading-none">
                                {lang.free_capitalize}
                            </span>
                        </a>
                    </div>

                    <hr className="my-2"></hr>

                    <div className="flex my-2">
                        <a href={generateUrl(langCode, "/faq")}>
                            {lang.faq}
                        </a>
                    </div>

                    <div className="flex my-2">
                        <a href={generateUrl(langCode, "/about-us")}>
                            {lang.about_us}
                        </a>
                    </div>

                    <div className="flex my-2">
                        <a href={generateUrl(langCode, "/career")}>
                            {lang.career}
                        </a>
                    </div>

                    <div className="flex my-2">
                        <a href={generateUrl(langCode, "/contact-us")}>
                            {lang.contact_us}
                        </a>
                    </div>

                    {
                        (url?.includes('signin') || url?.includes('signup')) ?
                            null
                            :
                            <>
                                <hr className="my-2"></hr>

                                {
                                    isLoggedIn ?
                                        <>
                                            <a href={generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/account`)} className="flex flex-row my-2 items-center">
                                                <img src="https://assets.jendela360.com/jendela/assets/images/svg/account-alt.svg" alt="account" className="mr-2" style={{ height: 16 }} />
                                                <p>{name()}</p>
                                            </a>
                                            <a href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/favourites`} className="flex flex-row my-2 items-center">
                                                <img src="https://assets.jendela360.com/jendela/assets/images/svg/heart-alt.svg" alt="heart" className="mr-2" style={{ height: 16 }} />
                                                <p>{lang.favourite}</p>
                                            </a>
                                            {
                                                refCode !== '' &&
                                                <a href={generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/account/ref-code`)} className="flex flex-row my-2 items-center">
                                                    <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/datadisplay-icon-referallink.svg`} alt="referal" className="mr-2" style={{ height: 16 }} />
                                                    <p>Referal Link</p>
                                                </a>
                                            }
                                            {
                                                isOwner === '1' ?
                                                    <a href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/owner-dashboard`} className="flex flex-row my-2 items-center">
                                                        <img src="https://assets.jendela360.com/jendela/assets/images/svg/unit-key.svg" alt="unit-key" className="mr-2" style={{ height: 16 }} />
                                                        <p>Owner Dashboard</p>
                                                    </a>
                                                    : null
                                            }
                                            {
                                                isBM ?
                                                    <a href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/bm-dashboard`} className="flex flex-row my-2 items-center">
                                                        <img src="https://assets.jendela360.com/jendela/assets/images/svg/bm-dashboard.svg" alt="bm" className="mr-2" style={{ height: 16 }} />
                                                        <p>BM Dashboard</p>
                                                    </a>
                                                    : null
                                            }
                                            <button onClick={() => logout()} className="flex flex-row my-2 w-full outline-none items-center">
                                                <img src="https://assets.jendela360.com/jendela/assets/images/svg/signout-alt.svg" alt="signout" className="mr-2" style={{ height: 16 }} />
                                                <p>{lang.log_out}</p>
                                            </button>
                                        </>
                                        :
                                        <>
                                            <div className="flex my-2 cursor-pointer">
                                                {/* <a onClick={() => {toggleNewWindow(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/auth/google`)}}> */}
                                                <a href={generateUrl(langCode, "/signin")} className="flex flex-row items-center">
                                                    <img src={`https://assets.jendela360.com/jendela/assets/images/svg/signin-alt.svg`} width="18" height="18" alt="login" className="mr-2" />
                                                    {lang.login}
                                                </a>
                                            </div>

                                            <div className="flex my-2">
                                                <a href={generateUrl(langCode, "/signup")} className="flex flex-row items-center">
                                                    <img src={`https://assets.jendela360.com/jendela/assets/images/svg/signup-alt.svg`} width="18" height="18" alt="sign up" className="mr-2" />
                                                    {lang.sign_up}
                                                </a>
                                            </div>
                                        </>
                                }
                            </>
                    }

                    <hr className="my-2"></hr>


                    <LanguageSwitch
                        langCode={typeof langCode !== "undefined" && langCode === 'en' ? 'en' : 'id'}
                        changeLang={changeLang}
                        className="items-center"
                    />
                </div>
            </div>
            : null
    )


}